<template>
  <ContentView :content="termsAndConditions[defaultLanguage]" />
</template>

<script>
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "@/store";
import ContentView from "@/components/ContentView";

export default defineComponent({
  components: {
    ContentView
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("login")
        .removeClass("error-page")
        .addClass("main");
    });

    const store = useStore();

    const defaultLanguage = computed(
      () => store.getters["app/default_language"]
    );
    const termsAndConditions = computed(
      () => store.getters["client/terms_and_conditions"] || {}
    );

    return {
      defaultLanguage,
      termsAndConditions
    };
  }
});
</script>
