<template>
  <div>
    <div class="sm:px-10">
      <div class="xl:flex flex-col min-h-screen mt-5">
        <!-- BEGIN: Logo -->
        <img
          alt="Chexpertise"
          class="w-60"
          :src="require(`@/assets/images/chexpertise.svg`)"
        />
        <!-- END: Logo -->

        <div class="flex-1 mt-16">
          <div class="content p-16">
            <!-- eslint-disable-next-line -->
            <div class="content" v-html="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    content: {
      type: String,
      default: ""
    }
  }
});
</script>

<style scoped>
.content :deep(h1:first-child) {
  margin-top: 0rem;
  font-size: 1.75rem;
  font-weight: bold;
}
.content :deep(h1) {
  margin-top: 1.5rem;
  margin-bottom: 1.75rem;
  font-size: 1.25rem;
  font-weight: bold;
}
.content :deep(h2) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.content :deep(h3) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
}
.content :deep(li) {
  list-style-type: disc;
  margin-left: 1rem;
}
</style>
